import React from 'react';
import {MultiBarChart} from "components/ui";
import { draw } from "patternomaly";
import interactiveStatisticsCharts from 'data/interactive-statistics-series-data.js';
import StatisticsData8 from 'data/data-8-treatment-admissions-by-gender.js';
import StatisticsEntry from 'templates/statistics-detail-page-layout';

const isBrowser = typeof window !== "undefined"

const StatisticsPage8=({location})=>{
        let thisLocationPathname = ''
        let dataSet1BackgroundColors = []
        if (isBrowser) {
            thisLocationPathname = window.location.pathname;
            dataSet1BackgroundColors = [draw('diagonal','#383E56')];
        }
        const pageData = interactiveStatisticsCharts.filter(data=>data.id === 7)
        const interactiveStatisticsPageData = pageData[0];
        
        return (
        <StatisticsEntry
        location={location}
        title={interactiveStatisticsPageData.title}
        description={interactiveStatisticsPageData.metaDescription}
        author={interactiveStatisticsPageData.author}
        year={interactiveStatisticsPageData.year}
        note={<span>*2020 Estimates may be influenced by the COVID-19 pandemic
            <br />
            Substance use flags represents reported use as the primary, secondary, or tertiary substance at the time of treatment admission.</span>}
        chartNotes={<><p>Men (n = 918,299)</p><p>Women (n = 497,175)</p></>}
        fileName={interactiveStatisticsPageData.fileName}
        fileSize={interactiveStatisticsPageData.fileSize}>
            <MultiBarChart 
            title={interactiveStatisticsPageData.title}
            chartLabels={[...StatisticsData8.map(data=>data.xAxis)]}
            dataSet1={[...StatisticsData8.map(data=>data.dataSet1)]}
            dataSet2={[...StatisticsData8.map(data=>data.dataSet2)]}
            dataSetLabel1="Men"
            dataSetLabel2="Women"
            xAxisLabel="Substance Use"
            yAxisLabel="Percentage"
            dataSet1BackgroundColor={dataSet1BackgroundColors}
            dataSet2BackgroundColor={["#ED7D31"]}
            isPercent={true}
            />
            <p>
                Alcohol was the most frequently identified substance reported at treatment admission for both men and women, followed by marijuana and heroin. 
                While this data summarizes the national trends for treatment admission, state specific patterns of use differ widely. 
                Collaboratives and stakeholders are encouraged to look at their state specific data trends.
            </p>
        </StatisticsEntry>
    )
}
export default StatisticsPage8;