import React from 'react';
import {Breadcrumb, Button } from "components/ui";
import Layout from 'components/layout';
import SeoComponent from 'components/seo';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"


//default layout for resource detail pages
const StatisticsEntry = ({ title, description, keywords, location, children, ...rest }) => {
    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    const seo = {
        metaTitle: title,
        metaDescription: description
    }
  return (
    <Layout location={location}>
    <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <div className="grid-container padding-bottom-0">
            <Breadcrumb pathname={thisLocationPathname} customCurrentPage={title} />
            <h1 name="main-content" id="main-content">{title}</h1>
            <div className="desktop:padding-bottom-5">
                <div className="grid-row">
                    <div className="grid-col-12">
                        <p><strong>Source:</strong> {rest.author}, {rest.year}</p>
                        {rest.chartNotes}
                    </div>
                </div>
            </div>
            <div className="padding-bottom-5">
                {children}
                <p><strong>Note:</strong> {rest.note}</p>
                <Button color="primary-button" type="button" size="padding-x-5" link={`/files/${rest.fileName}`} labelText={`View source data for ${title}`}>
                    View Source Data (PDF {rest.fileSize})
                </Button>
                <p>Section: Policy and Practice Resources</p>
            </div>
        </div>       
    </Layout>
);};
export default StatisticsEntry;


