const StatisticsData8=[
    {
        xAxis: "Alcohol",
        dataSet1: "45.1",
        dataSet2: "36.3"
    },
    {
        xAxis: "Heroin",
        dataSet1: "25.7",
        dataSet2: "23.8"
    },
    {
        xAxis: ["Other Opiates", "and Synthetics"],
        dataSet1: "9.4",
        dataSet2: "11.9"
    },
    {
        xAxis: ["Cocaine (Smoked or", "Other Route)"],
        dataSet1: "17.7",
        dataSet2: "16.7"
    },
    {
        xAxis: "Marijuana",
        dataSet1: "28.6",
        dataSet2: "25.2"
    },
    {
        xAxis: "Methamphetamines",
        dataSet1: "17.3",
        dataSet2: "23.6"
    },
    {
        xAxis: "Benzodiazephines",
        dataSet1: "4.7",
        dataSet2: "4.9"
    }
]
export default StatisticsData8;